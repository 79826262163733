import React, { useEffect, useState } from "react";// chargement des composants react
import { useDispatch, useSelector } from "react-redux";// chargement des fonction de gestion du store 
// import des fonctions de gestion du store
import {
  listHoraires,
  updateHoraires,
} from "../../features/slice/horaireSlice";
//
//page Horaires (pas de props passées)
const Horaires = () => {
  const dispatch = useDispatch();// fonction d'appel des slice
  const role = useSelector((state) => state.utilisateur.role); // role de la personne identifiée : admin ou consultant
  const horaires = useSelector((state) => state.horaire.horaire);// horaires définis
  const [hasLoadedDataHoraire, setHasLoadedDataHoraire] = useState(false);// drapeau de chargement des données
  const [horairesModif, setHorairesModif] = useState([]);// tableau contenant les horaires modifiés
  useEffect(() => {
    async function fetchData() {
      if (!hasLoadedDataHoraire) {
        try {
          const response = dispatch(listHoraires()); // appel du slice de chargement des données auprés de la BD;
          if (response) {
            // si on obtient une réponse
            if (response.payload.okay === "false") {
              // si le flag okay est faux c'est que la BD est vide
              // Gérer le cas où la table est vide
              //setMessage(response.payload.message); // récupère le message renvoyé par le serveur (base vide)
              setHasLoadedDataHoraire(true); // Marquer que les données ont été chargées pour autoriser l'affichage
            } else {
              // Les données sont valides

              localStorage.setItem("hasLoadedData", "true"); // garde la valeur du flag de chargement des données dans le stockage local
              // setMessage(""); // effacer le message sinon réapparait
            }
          }
        } catch (error) {
          // en cas d'erreur lors de l'interrogation de la BD
          setHasLoadedDataHoraire(true); // Marquer que les données ont été chargées
          /*setMessage(
          "Une erreur est survenue lors de la recherche des partenaires."
        );*/
        }
      }
    }
    fetchData();
  }, [dispatch, setHasLoadedDataHoraire, horaires]);
  // Mettre à jour horairestest avec les horaires initiaux du store Redux
  useEffect(() => {
    if (hasLoadedDataHoraire) {
      setHorairesModif(horaires);
    }
  }, [horaires]);
  // focntion de modification des horaires
  const handleChange = (idHoraire, newValue) => {
    // si role est admin alors on peut modifier les champs
    if (role === 1) {
      // Mettre à jour l'horaire correspondant dans horairesModif
      const updatedHoraires = horairesModif.map((horaire) =>
        horaire.id_horaire === idHoraire
          ? { ...horaire, Horaires: newValue }
          : horaire
      );
      setHorairesModif(updatedHoraires);
    }
  };
// focntion de sauvegarde des horaires
  const saveData = () => {
    dispatch(updateHoraires({ horairesModif: horairesModif }));
  };

  return (
    <div className="horaire">
      {/* Titre*/}
      <h2>HORAIRES D'OUVERTURE</h2>
      {/* si les données sont chargées alors on les affiche et si role "admin" alors on permet la modification*/}
      {hasLoadedDataHoraire && (
        <div className="horaires-list">
          {horairesModif.map(({ id_horaire, Jour, Horaires }) => (
            <div key={id_horaire} className="horaire-item">
              <label>{`${
                Jour.charAt(0).toUpperCase() + Jour.slice(1)
              } : `}</label>
              <input
                type="text"
                placeholder={Horaires}
                value={Horaires}
                onChange={(e) => handleChange(id_horaire, e.target.value)}
                disabled={role !== 1}
                required
              />
            </div>
          ))}
        </div>
      )}
{/* Si role "admin", on affice la bouton de sauvegarde*/}
      {role === 1 && (
        <button className="boutonoccase" onClick={saveData}>
          Save
        </button>
      )}
    </div>
  );
};

export default Horaires;
