// tableau d'objet contenant les éléments nécessaire à l'affichage des titres des pages et des données pour la navigation entre les pages. Choix permettant de mutualiser les éléments
export const QualiteAgent = [
  {
    id: 1,
    qualite: "administrateur",
  },
  {
    id: 2,
    qualite: "personnel",
  },
];
